import React, { Component } from 'react';
import { Col, FormGroup, Label, Input } from 'reactstrap';

export class InputWithLabel extends Component {
    
    render() {
        let value = this.props.defaultValue ? this.props.defaultValue : "";
        return (
            <FormGroup row>
                <Label for={this.props.name} sm={4}>{this.props.label}</Label>
                <Col sm={8}>
                    <Input type="text" name={this.props.name} onChange={this.props.onChange} defaultValue={value} />
                </Col>
            </FormGroup>
        );
    }
}