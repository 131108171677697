import React, { Component } from 'react';
import { WriteAccessRequiredButton } from '../../UI/WriteAccessRequiredButton/WriteAccessRequiredButton'
import { GlobalAuthenticatedHttpClient } from '../../Authentication/AuthenticatedHttpClient';
import { EditableTable, ColumnType } from '../../UI/EditableTable/EditableTable';
import { AddOrEditSequenceReceiptModal } from './AddOrEditSequenceReceiptModal';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../../../TelemetryService';
import "./BrowseSequenceReceiptExamples.css";

const endPoint = "api/SequenceReceiptExamples";

class BrowseSequenceReceiptExamples extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
    this.modalRef = React.createRef();
  }

  async componentDidMount() {
    await this.loadData();
  }

  render() {
    let stateDropdownElements = [
      { title: "New", color: "#33b5e5" },
      { title: "Changed", color: "#ffbb33" },
      { title: "Valid", color: "#00C851" },
      { title: "Outdated", color: "#868e96" },
      { title: "Invalid", color: "#ff4444" },
    ];
    let columns = [
      { type: ColumnType.Fixed, title: "Id", width: 200 },
      { type: ColumnType.Editable, title: "Title" },
      { type: ColumnType.Editable, title: "Description" },
      { type: ColumnType.Dropdown, title: "State", dropdownElements: stateDropdownElements },
      { type: ColumnType.CustomContent, title: "Sequence Receipts", renderContent: item => this.renderSequenceReceiptsField(item) }
    ];

    let content = (
      <div>
        {this.getAddElementModal()}
        <div className="header-container">
          <h1 className="header">Browse SequenceReceiptExamples</h1>
          <WriteAccessRequiredButton className="btn-add" color="success" onClick={() => this.modalRef.current.toggleModal()}>Add new item</WriteAccessRequiredButton>
        </div>
        <EditableTable data={this.state.data} columns={columns} onUpdateItem={item => this.updateItem(item)}
          onEditButtonClick={(sender, item) => this.editItem(item)} />
      </div>);

    return this.state.loading
      ? <p><em>Loading...</em></p>
      : content;
  }

  async updateItem(item) {
    await GlobalAuthenticatedHttpClient.authorizedPut(`${endPoint}/${item.Id}`, JSON.stringify(item));
  }

  async createItem(item) {
      await GlobalAuthenticatedHttpClient.authorizedPost(endPoint, JSON.stringify(item));
      await this.loadData();
  }

  async loadData() {
      let response = await GlobalAuthenticatedHttpClient.authorizedGet(`${endPoint}/?$expand=SequenceReceipts`);
      let data = await response.json();
      this.setState({
          data: data.value, loading: false
      });
  }

  editItem(item) {
    this.modalRef.current.toggleModal(item)
  }

  renderSequenceReceiptsField(item) {
    const count = item.SequenceReceipts.length;
    return <div>{count} examples</div>
  }

  getAddElementModal() {
    let inputs = [
      { accessor: "Id", label: "Id" },
      { accessor: "Market", label: "Market" },
      { accessor: "Title", label: "Title" },
      { accessor: "Description", label: "Description" },
      { accessor: "DescriptionLang", label: "Description Language" }
    ];
    return <AddOrEditSequenceReceiptModal ref={this.modalRef} inputs={inputs} title="New SequenceReceiptExample" onSubmit={item => this.createItem(item)} />;
  }
}

export default withAITracking(ai.reactPlugin, BrowseSequenceReceiptExamples);