import React, { Component } from 'react';
import { AddOrEditModal } from '../../UI/AddOrEditModal/AddOrEditModal'
import { DragAndDropBox } from '../../UI/DragAndDropBox/DragAndDropBox'
import { GlobalAuthenticatedHttpClient } from '../../Authentication/AuthenticatedHttpClient';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';

export class AddOrEditSingleReceiptModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            industries: [],
            prerequisites: [],
            selectedIndustries: [],
            selectedPrerequisites: [],
            item: props.item ? props.item : {}
        }
        this.modalRef = React.createRef();
        this.industriesBoxRef = React.createRef();
        this.prerequisitesBoxRef = React.createRef();
    }

    async toggleModal(item = undefined) {
        if (item) {
            this.setState({
                selectedIndustries: item.Industries.map(this.createIndustryBoxItem),
                selectedPrerequisites: item.Prerequisites.map(this.createPrerequisiteBoxItem)
            });
        }

        if (!this.modalRef.current.isOpen()) {
            await this.loadData();
            this.modalRef.current.toggleModal(item);
        }
        else {
            this.modalRef.current.toggleModal();
        }
    }

    async loadData() {
        let industriesResponse = await GlobalAuthenticatedHttpClient.authorizedGet("api/Industries");
        let industries = await industriesResponse.json();
        let prerequisitesResponse = await GlobalAuthenticatedHttpClient.authorizedGet("api/Prerequisites");
        let prerequisites = await prerequisitesResponse.json();
        if(industries.value && prerequisites.value){
            this.setState({
                industries: industries.value.filter(x => !this.state.selectedIndustries.some(y => y.example.Id === x.Id)),
                prerequisites: prerequisites.value.filter(x => !this.state.selectedPrerequisites.some(y => y.example.Id === x.Id))
            });
        }
    }

    createIndustryBoxItem(industry) {
        return {
            label: <div title={industry.Description} style={{ display: "inline" }}><b>{industry.Title}</b></div>,
            uid: industry.Id,
            example: industry
        };
    }

    createPrerequisiteBoxItem(prerequisite) {
        return {
            label: <div title={prerequisite.Description} style={{ display: "inline" }}><b>{prerequisite.Title}</b></div>,
            uid: prerequisite.Id,
            example: prerequisite
        };
    }

    filterIndustries = (item, filterValue) => {
        return item &&
            (item.example.Id.includes(filterValue) ||
                (item.example.Title ? item.example.Title.includes(filterValue) : false) ||
                (item.example.Description ? item.example.Description.includes(filterValue) : false));
    }

    filterPrerequisites = (item, filterValue) => {
        return item &&
            (item.example.Id.includes(filterValue) ||
                (item.example.Title ? item.example.Title.includes(filterValue) : false) ||
                (item.example.Description ? item.example.Description.includes(filterValue) : false));
    }

    onSubmit(item) {
        item.Industries = this.industriesBoxRef.current.state.items.map(x => x.example);
        item.Prerequisites = this.prerequisitesBoxRef.current.state.items.map(x => x.example);

        if (this.props.onSubmit) {
            this.props.onSubmit(item);
        }
    }

    render() {
        let inputs = [
            { accessor: "BusinessCase", label: "BusinessCase" },
            { accessor: "Request", label: "Request" },
			{ accessor: "Response", label: "Response" }
		];
		let textAreas = [
			{ accessor: "Description", label: "Description" }
		];
        return (
			<AddOrEditModal ref={this.modalRef} inputs={inputs} textAreas={textAreas} title="New SingleReceiptExample" className="full-screen-modal" item={this.props.item} onSubmit={item => this.onSubmit(item)}>
                <Row>
                    <Col sm={6}>
                        <Card>
                            <CardHeader>Prerequisites</CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm={6}>
                                        <p className="label">Assigned</p>
                                        <DragAndDropBox targetKey="prerequisites" items={this.state.selectedPrerequisites} ref={this.prerequisitesBoxRef}
                                            filterMethod={this.filterPrerequisites} />
                                    </Col>
                                    <Col sm={6}>
                                        <p className="label">All available</p>
                                        <DragAndDropBox targetKey="prerequisites" items={this.state.prerequisites.map(this.createPrerequisiteBoxItem)}
                                            filterMethod={this.filterPrerequisites} />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={6}>
                        <Card>
                            <CardHeader>Industries</CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm={6}>
                                        <p className="label">Assigned</p>
                                        <DragAndDropBox targetKey="industries" items={this.state.selectedIndustries} ref={this.industriesBoxRef}
                                            filterMethod={this.filterIndustries} />
                                    </Col>
                                    <Col sm={6}>
                                        <p className="label">All available</p>
                                        <DragAndDropBox targetKey="industries" items={this.state.industries.map(this.createIndustryBoxItem)}
                                            filterMethod={this.filterIndustries} />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>

                </Row>
            </AddOrEditModal>);
    }
}
