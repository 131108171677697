import { GlobalAuthenticationContext } from "./AuthenticationContext";

class AuthenticatedHttpClient {

  async authorizedGet(url) {
    return await this.authorizedFetch("GET", url);
  }

  async authorizedPost(url, body) {
    return await this.authorizedFetch("POST", url, body);
  }

  async authorizedPut(url, body) {
    return await this.authorizedFetch("PUT", url, body);
  }

  async authorizedFetch(method, url, body) {
    const tokenResponse = await GlobalAuthenticationContext.getToken();
    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + tokenResponse.accessToken
    };
    return await fetch(url, {
      method: method,
      headers: headers,
      body: body
    });
  }
}

export const GlobalAuthenticatedHttpClient = new AuthenticatedHttpClient();