import React, { Component } from 'react';
import { InputWithLabel } from './InputWithLabel'
import { TextAreaWithLabel } from './TextAreaWithLabel'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';
import { WriteAccessRequiredButton } from '../WriteAccessRequiredButton/WriteAccessRequiredButton'

export class AddOrEditModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            item: props.item ? props.item : {}
        }
    }

    isOpen() {
        return this.state.isModalOpen;
    }

    toggleModal(item = undefined) {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
            item: item ? item : {}
        });
    }

    submitModal() {
        if (this.props.onSubmit) {
            this.props.onSubmit(this.state.item);
        }
        this.toggleModal();
    }

    getDefaultValue(accessor) {
        return this.state.item[accessor] ? this.state.item[accessor] : "";
    }

    onInputChange(e) {
        this.setState({
            item: {
                ...this.state.item,
                [e.target.name]: e.target.value
            }
        });
    }

    render() {
        return (
            <Modal isOpen={this.state.isModalOpen} toggle={() => this.toggleModal()} className={this.props.className}>
                <ModalHeader toggle={() => this.toggleModal()}>{this.props.title}</ModalHeader>
                <ModalBody>
                    <Form>
                        {this.props.inputs && this.props.inputs.map(x =>
                            <InputWithLabel key={x.accessor} name={x.accessor} label={x.label} defaultValue={this.getDefaultValue(x.accessor)}
                                onChange={(e) => this.onInputChange(e)} />
						)}
                        {this.props.textAreas && this.props.textAreas.map(x =>
							<TextAreaWithLabel key={x.accessor} name={x.accessor} label={x.label} defaultValue={this.getDefaultValue(x.accessor)}
								onChange={(e) => this.onInputChange(e)} />
						)}
                    </Form>
                    {this.props.children}
                </ModalBody>
                <ModalFooter>
                    <WriteAccessRequiredButton color="primary" onClick={() => this.submitModal()}>OK</WriteAccessRequiredButton>{' '}
                    <Button color="secondary" onClick={() => this.toggleModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>);
    }
}