import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout/Layout';
import { Home } from './components/Home/Home';
import BrowseAccountNumberFrameworks from './components/BrowseData/BrowseAccountNumberFrameworks/BrowseAccountNumberFrameworks';
import BrowseBusinessCases from './components/BrowseData/BrowseBusinessCases/BrowseBusinessCases';
import BrowseChargeItemCases from './components/BrowseData/BrowseChargeItemCases/BrowseChargeItemCases';
import BrowsePayItemCases from './components/BrowseData/BrowsePayItemCases/BrowsePayItemCases';
import BrowseReceiptCases from './components/BrowseData/BrowseReceiptCases/BrowseReceiptCases';
import BrowseReceiptCaseFlags from './components/BrowseData/BrowseReceiptCaseFlags/BrowseReceiptCaseFlags';
import BrowsePrerequisites from './components/BrowseData/BrowsePrerequisites/BrowsePrerequisites';
import BrowseIndustries from './components/BrowseData/BrowseIndustries/BrowseIndustries';
import BrowseSingleReceiptExamples from './components/Examples/BrowseSingleReceiptExamples/BrowseSingleReceiptExamples';
import SingleReceiptExamplePattern from './components/Examples/SingleReceiptExamplePattern/SingleReceiptExamplePattern';
import BrowseSequenceReceiptExamples from './components/Examples/BrowseSequenceReceiptExamples/BrowseSequenceReceiptExamples';
import { GlobalAuthenticationContext } from "./components/Authentication/AuthenticationContext"

export default class App extends Component {
  static displayName = App.name;

  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: GlobalAuthenticationContext.isLoggedIn()
    }
  }

  render() {
    if (this.state.isLoggedIn) {
      return (
        <Layout>
          <Route exact path="/" component={Home} />
          <Route path='/data/ChargeItemCases' component={BrowseChargeItemCases} />
          <Route path='/data/AccountNumberFrameworks' component={BrowseAccountNumberFrameworks} />
          <Route path='/data/BusinessCases' component={BrowseBusinessCases} />
          <Route path='/data/PayItemCases' component={BrowsePayItemCases} />
          <Route path='/data/ReceiptCases' component={BrowseReceiptCases} />
          <Route path='/data/ReceiptCaseFlags' component={BrowseReceiptCaseFlags} />
          <Route path='/examples/SingleReceiptExamplePatterns/create/:singleReceiptExampleId' component={SingleReceiptExamplePattern} />
          <Route exact path='/examples/SingleReceiptExamplePatterns/:singleReceiptExamplePatternId' component={SingleReceiptExamplePattern} />
          <Route path='/examples/SingleReceiptExamples' component={BrowseSingleReceiptExamples} />
          <Route path='/examples/SequenceReceiptExamples' component={BrowseSequenceReceiptExamples} />
          <Route path='/data/BrowsePrerequisites' component={BrowsePrerequisites} />
          <Route path='/data/BrowseIndustries' component={BrowseIndustries} />
        </Layout>
      );
    }
    else {
      GlobalAuthenticationContext.login();
      return (<div>Loading.....</div>)
    }
  }
}
