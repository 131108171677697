import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { GlobalAuthenticationContext } from '../../Authentication/AuthenticationContext';


export class WriteAccessRequiredButton extends Component {
    render() {
        return GlobalAuthenticationContext.canUserWrite()
            ? (<Button className={this.props.className} color={this.props.color} onClick={this.props.onClick}>{this.props.children}</Button>)
            : <Button className={this.props.className} color={this.props.color} disabled title="Access denied.">{this.props.children}</Button>;
    }
}