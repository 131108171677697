import React from "react";
import { BoxItem } from "./BoxItem";
import { DropTarget } from 'react-drag-drop-container';
import { Input } from 'reactstrap';
var shortid = require('shortid');
var uuid = require('uuid');

export class DragAndDropBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.items ? props.items : [],
      componentId: uuid()
    };
  }

  handleDrop = (e) => {
    let items = this.state.items.slice();
    const removeOnDrag = this.props.removeOnDrag === undefined || this.props.removeOnDrag;
    if(!removeOnDrag && e.dragData.draggedFrom !== this.state.componentId && items.some(x => x.example.Id === e.dragData.example.Id)) {
      return;
    }

    items.push({ label: e.dragData.label, example: e.dragData.example, uid: shortid.generate() });
    this.setState({ items: items });
  };

  swap = (fromIndex, toIndex, dragData, draggedFrom) => {
    let items = this.state.items.slice();
    const removeOnDrag = this.props.removeOnDrag === undefined || this.props.removeOnDrag;
    if(!removeOnDrag && draggedFrom !== this.state.componentId && items.some(x => x.example.Id === dragData.example.Id)) {
      return;
    }
    const item = { label: dragData.label, example: dragData.example, uid: shortid.generate() };
    items.splice(toIndex, 0, item);
    this.setState({ items: items });
  };

  kill = (uid, droppedOn) => {
    const removeOnDrag = this.props.removeOnDrag === undefined || this.props.removeOnDrag;
    if (removeOnDrag || droppedOn === this.state.componentId) {
      let items = this.state.items.slice();
      const index = items.findIndex((item) => {
        return item.uid === uid
      });
      if (index !== -1) {
        items.splice(index, 1);
      }
      this.setState({ items: items });
    }
  };

  handleChange = (e) => {
    let newItems = this.state.items.map(x => {
      x.hidden = typeof (this.props.filterMethod) === typeof (Function) ? !this.props.filterMethod(x, e.target.value) : !this.defaultfilterItems(x, e.target.value);
      return x;
    });
    this.setState({
      items: newItems
    });
  }

  defaultfilterItems = (item, filterValue) => {
    return item;
  }

  render() {
    return (
      <div className="component_box">
        <Input type="text" name="hi" onChange={this.handleChange} placeholder="Filter..." />
        <DropTarget
          onHit={this.handleDrop}
          targetKey={this.props.targetKey}
          dropData={{ droppedOn: this.state.componentId }}
        >
          <div className="box">
            {this.state.items.map((item, index) => {
              return (
                <BoxItem key={item.uid} hidden={item.hidden} uid={item.uid} kill={this.kill} index={index} swap={this.swap}
                  example={item.example} targetKey={this.props.targetKey} parentId={this.state.componentId}>
                  {item.label}
                </BoxItem>
              )
            })}
          </div>
        </DropTarget>
      </div>
    );
  }
}