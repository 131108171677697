import React, { Component } from 'react';
import { AddOrEditModal } from '../../UI/AddOrEditModal/AddOrEditModal'
import { DragAndDropBox } from '../../UI/DragAndDropBox/DragAndDropBox'
import { GlobalAuthenticatedHttpClient } from '../../Authentication/AuthenticatedHttpClient';
import { Row, Col } from 'reactstrap';
var shortid = require('shortid');

export class AddOrEditSequenceReceiptModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            singleReceipts: [],
            selectedSingleReceipts: [],
            item: props.item ? props.item : {}
        }
        this.modalRef = React.createRef();
        this.singleReceiptsBoxRef = React.createRef();
    }

    async toggleModal(item = undefined) {
        this.setState({
            selectedSingleReceipts: item ? item.SequenceReceipts.map(this.createSingleReceiptBoxItem) : []
        });

        if (!this.modalRef.current.isOpen()) {
            await this.loadData();
            this.modalRef.current.toggleModal(item);
        }
        else {
            this.modalRef.current.toggleModal();
        }
    }

    async loadData() {
        const response = await GlobalAuthenticatedHttpClient.authorizedGet("api/SingleReceiptExamples");
        const data = await response.json();
        this.setState({
            singleReceipts: data.value
        });
    }

    createSingleReceiptBoxItem(receipt) {
        return {
            label:
                (<div style={{ display: "inline" }} title={receipt.Request ? receipt.Request : "No request available"}>
                    <b>{receipt.BusinessCase}</b><br />
                    <i>{receipt.Id}</i>
                </div>),
            uid: shortid.generate(),
            example: receipt
        };
    }

    filterReceipts = (item, filterValue) => {
        return item &&
            (item.example.Id.includes(filterValue) ||
                (item.example.BusinessCase ? item.example.BusinessCase.includes(filterValue) : false) ||
                (item.example.Request ? item.example.Request.includes(filterValue) : false) ||
                (item.example.Response ? item.example.Response.includes(filterValue) : false));
    }

    onSubmit(item) {
        item.SequenceReceipts = this.singleReceiptsBoxRef.current.state.items.map(x => x.example);

        if (this.props.onSubmit) {
            this.props.onSubmit(item);
        }
    }

    render() {
        let inputs = [
            { accessor: "Title", label: "Title" },
            { accessor: "Description", label: "Description" }
        ];
        return (
            <AddOrEditModal ref={this.modalRef} inputs={inputs} title="New SingleReceiptExample" item={this.props.item} onSubmit={item => this.onSubmit(item)}>
                <Row>
                    <Col sm={6}>
                        <p className="label">Assigned receipts</p>
                        <DragAndDropBox targetKey="receipts" items={this.state.selectedSingleReceipts} ref={this.singleReceiptsBoxRef}
                            filterMethod={this.filterReceipts} />
                    </Col>
                    <Col sm={6}>
                        <p className="label">All receipts</p>
                        <DragAndDropBox targetKey="receipts" items={this.state.singleReceipts.map(this.createSingleReceiptBoxItem)} removeOnDrag={false}
                            filterMethod={this.filterReceipts} />
                    </Col>
                </Row>
                <div className="mt-1">
                    <i>Hover over examples to display their linked request.</i>
                </div>
            </AddOrEditModal>);
    }
}
