import React from "react";
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';

export class BoxItem extends React.Component {

  handleDrop = (e) => {
    e.stopPropagation();
    this.props.swap(e.dragData.index, this.props.index, e.dragData, e.dragData.draggedFrom);
  };

  deleteMe = (e) => {
    this.props.kill(this.props.uid, e.dropData.droppedOn);
  };

  render() {
    let display = this.props.hidden ? "none" : "block";
    return (
      <div className="box_item_component" style={{ display: display }}>
        <DragDropContainer
          targetKey={this.props.targetKey}
          dragData={{ label: this.props.children, index: this.props.index, example: this.props.example, uid: this.props.uid, draggedFrom: this.props.parentId }}
          onDrop={this.deleteMe}
          
          disappearDraggedElement={true}
          dragHandleClassName="item"
        >
          <DropTarget
            onHit={this.handleDrop}
            targetKey={this.props.targetKey}
            dropData={{ droppedOn: this.props.parentId }}
          >
            <div className="outer">
              <div className="item">
                <span className="grabber fas fa-arrows-alt"></span>
                {this.props.children}
              </div>
            </div>
          </DropTarget>
        </DragDropContainer>
      </div>
    );
  }
}