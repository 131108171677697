import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { GlobalAuthenticationContext } from "../Authentication/AuthenticationContext"
import logo from '../../static/fiskaltrust.png'

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            userInfo: GlobalAuthenticationContext.getUserInfo() 
        };
    }

    userInfoCallback = (userInfo) => {
        this.setState({ userInfo });
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/"><img alt="logo" src={logo} className="logo" /> Business Case Database</NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
                                </NavItem>
                                {this.state.userInfo &&
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret>
                                            Browse data
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            <NavLink tag={Link} className="text-dark" to="/data/AccountNumberFrameworks">AccountNumberFrameworks</NavLink>
                                            <NavLink tag={Link} className="text-dark" to="/data/BusinessCases">BusinessCases</NavLink>
                                            <NavLink tag={Link} className="text-dark" to="/data/ChargeItemCases">ChargeItemCases</NavLink>
                                            <NavLink tag={Link} className="text-dark" to="/data/PayItemCases">PayItemCases</NavLink>
                                            <NavLink tag={Link} className="text-dark" to="/data/ReceiptCases">ReceiptCases</NavLink>
                                            <NavLink tag={Link} className="text-dark" to="/data/ReceiptCaseFlags">ReceiptCaseFlags</NavLink>
                                            <NavLink tag={Link} className="text-dark" to="/data/BrowseIndustries">Industries</NavLink>
                                            <NavLink tag={Link} className="text-dark" to="/data/BrowsePrerequisites">Prerequisites</NavLink>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>                                    
                                }
                                {this.state.userInfo &&
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret>
                                            Examples
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            <NavLink tag={Link} className="text-dark" to="/examples/SingleReceiptExamples">SingleReceiptExamples</NavLink>
                                            <NavLink tag={Link} className="text-dark" to="/examples/SequenceReceiptExamples">SequenceReceiptExamples</NavLink>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>                                    
                                }
                                {this.state.userInfo ? 
                                    <NavItem className="nav-link">            
                                        {this.state.userInfo.name}&nbsp;
                                        (<a href="#/" onClick={GlobalAuthenticationContext.logout} className="Button">Logout</a>)            
                                    </NavItem>
                                    : <p>Login</p>
                                }
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
