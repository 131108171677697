import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { WriteAccessRequiredButton } from '../../UI/WriteAccessRequiredButton/WriteAccessRequiredButton'
import { GlobalAuthenticatedHttpClient } from '../../Authentication/AuthenticatedHttpClient';
import { EditableTable, ColumnType } from '../../UI/EditableTable/EditableTable';
import { AddOrEditSingleReceiptModal } from './AddOrEditSingleReceiptModal'
import { DetailViewModal } from './DetailViewModal';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../../../TelemetryService';
import '../../BrowseData/BrowseData.css';
import './BrowseSingleReceiptExamples.css';

const endPoint = "api/SingleReceiptExamples";

class BrowseSingleReceiptExamples extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
        this.modalRef = React.createRef();
        this.requestDetailModalRef = React.createRef();;
        this.responseDetailModalRef = React.createRef();;
    }

    async componentDidMount() {
        await this.loadData();
    }

    render() {
        let stateDropdownElements = [
            { title: "New", color: "#33b5e5" },
            { title: "Changed", color: "#ffbb33" },
            { title: "Valid", color: "#00C851" },
            { title: "Outdated", color: "#868e96" },
            { title: "Invalid", color: "#ff4444" },
        ];
        let columns = [
            { type: ColumnType.Fixed, title: "Id" },
            { type: ColumnType.Editable, title: "BusinessCase" },
            { type: ColumnType.Dropdown, title: "State", dropdownElements: stateDropdownElements },
            { type: ColumnType.CustomContent, title: "Request", renderContent: item => this.renderDetailView(item, "Request", this.requestDetailModalRef) },
            { type: ColumnType.CustomContent, title: "Response", renderContent: item => this.renderDetailView(item, "Response", this.responseDetailModalRef) },
            { type: ColumnType.CustomContent, title: "", width: 40, renderContent: item => this.renderCreatePatternButton(item) },
        ];

        let content = (
            <div>
                {this.getRequestDetailViewModal()}
                {this.getResponseDetailViewModal()}
                {this.getAddElementModal()}
                <div className="header-container">
                    <h1 className="header">Browse SingleReceiptExamples</h1>
                    <WriteAccessRequiredButton className="btn-add" color="success" onClick={() => this.modalRef.current.toggleModal()}>Add new item</WriteAccessRequiredButton>
                </div>
                <EditableTable data={this.state.data} columns={columns} onUpdateItem={item => this.updateItem(item)}
                    onEditButtonClick={(sender, item) => this.editItem(item)} />
            </div>);

        return this.state.loading
            ? <p><em>Loading...</em></p>
            : content;
    }


    async updateItem(item) {
		await GlobalAuthenticatedHttpClient.authorizedPut(`${endPoint}/${item.Id}`, JSON.stringify(item));
		await this.loadData();
    }
    
    async createItem(item) {
        await GlobalAuthenticatedHttpClient.authorizedPost(endPoint, JSON.stringify(item));
        await this.loadData();
    }
    
    async loadData() {
        let response = await GlobalAuthenticatedHttpClient.authorizedGet(`${endPoint}?$expand=Industries,Prerequisites,Pattern`);
        let data = await response.json();
        this.setState({
            data: data.value, loading: false
        });
    }

    editItem(item) {
        this.modalRef.current.toggleModal(item)
    }

    onSubmit(item) {
        if (item.Id) {
            this.updateItem(item);
        }
        else {
            this.createItem(item);
        }
    }

    toggleDetailView(item, accessor) {
        this.setState({
            isDetailViewOpen: !this.state.isDetailViewOpen,
            detailViewItem: item,
            detailViewAccessor: accessor
        });
    }

    renderDetailView(item, accessor, modalRef) {
        return (
            <div>
                <Button color="link" className="iconButton" onClick={() => modalRef.current.toggleModal(item, accessor)}>
                    <i title="Expand" className="fas fa-search"></i>
                </Button>
                {item[accessor]}
            </div>);
    }

    renderCreatePatternButton(item) {
        let url = item.Pattern
            ? `examples/SingleReceiptExamplePatterns/${item.Pattern.Id}`
            : `examples/SingleReceiptExamplePatterns/create/${item.Id}`;
        return (
            <Button color="link" className="p-0 w-100" onClick={() => window.location.pathname = url}>
                <i title="Create or edit pattern" className="far fa-file-alt text-center w-100"></i>
            </Button>);
    }



    getAddElementModal() {
        return <AddOrEditSingleReceiptModal ref={this.modalRef} item={this.state.item} onSubmit={item => this.onSubmit(item)} />
    }

    getRequestDetailViewModal() {
        return <DetailViewModal ref={this.requestDetailModalRef} accessor="Request" onSubmit={(item) => this.updateItem(item).then(async () => await this.loadData())} />
    }

    getResponseDetailViewModal() {
        return <DetailViewModal ref={this.responseDetailModalRef} accessor="Response" onSubmit={(item) => this.updateItem(item).then(async () => await this.loadData())} />
    }
}

export default withAITracking(ai.reactPlugin, BrowseSingleReceiptExamples);