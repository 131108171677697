import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export class DetailViewModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
            item: props.item ? props.item : {}
        }
    }

    toggleModal(item = undefined) {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
            item: item ? item : {},
            content: item ? item[this.props.accessor] : ""
        });
    }

    submitModal() {
        if (this.props.onSubmit) {
            this.props.onSubmit(this.state.item);
        }
        this.toggleModal();
    }

    onInputChange(e) {
        this.setState({
            item: {
                ...this.state.item,
                [this.props.accessor]: e.target.value
            }
        });
    }

    render() {
        return (
            <Modal isOpen={this.state.isModalOpen} toggle={() => this.toggleModal()} className="detailDialog">
                <ModalHeader toggle={() => this.toggleModal()}>{this.props.accessor}</ModalHeader>
                <ModalBody>
                    <div>
                        <textarea rows="25" onChange={e => this.onInputChange(e)} className="form-control" defaultValue={this.state.content} />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => this.submitModal()}>OK</Button>{' '}
                    <Button color="secondary" onClick={() => this.toggleModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>);
    }
}