import * as Msal from "msal";
import JwtDecode from "jwt-decode";

class AuthenticationContext {
  constructor() {
    const config = {
      auth: {
        clientId: process.env.REACT_APP_ClientId,
        authority: process.env.REACT_APP_Authority,
        redirectUri: window.location.origin,
      },
      cache: {
        cacheLocation: "localStorage"
      }
    };
    this.scopes = ["openid"];
    this.accessTokenScopes = [process.env.REACT_APP_ApiScope];
    this.userAgentApplication = new Msal.UserAgentApplication(config);
    this.userAgentApplication.handleRedirectCallback((error, response) => { });
  }

  login = () => {
    this.userAgentApplication.loginRedirect({
      scopes: this.scopes,
      authority: process.env.REACT_APP_Authority
    });
  };

  logout = () => {
    this.userAgentApplication.logout();
  };

  isLoggedIn = () => {
    const potentialLoggedInUser = this.userAgentApplication.getAccount();
    if (potentialLoggedInUser) {
      const idToken = localStorage.getItem("msal.idtoken");
      const oldIDToken = potentialLoggedInUser.idToken;
      if (oldIDToken.exp && idToken) {
        const expirationInMs = oldIDToken.exp * 1000;
        if (Date.now() < expirationInMs) {
          return true;
        }
      }
    }
    return false;
  };

  getToken = async () => {
    let accessTokenRequest = {
      scopes: this.accessTokenScopes,
      authority: process.env.REACT_APP_Authority
    };

    try {
      let accessTokenResponse = await this.userAgentApplication.acquireTokenSilent(accessTokenRequest);
      let token = JwtDecode(accessTokenResponse.accessToken);
      localStorage.setItem("roles", token.roles);
      return accessTokenResponse;
    } catch (error) {
      console.error(error);
      localStorage.removeItem("roles");
      this.userAgentApplication.acquireTokenRedirect(accessTokenRequest);
      throw error;
    }
  };

  getUserInfo = () => {
    return this.userAgentApplication.getAccount();
  };

  canUserWrite = () => {
    if (this.isLoggedIn()) {
      let roles = localStorage.getItem("roles");
      return roles && (roles.includes("OData.Full") || roles.includes("OData.Write"));
    }
    return false;
  };
}

export const GlobalAuthenticationContext = new AuthenticationContext();
