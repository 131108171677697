import React, { Component } from 'react';
import { FormGroup, Input, CustomInput } from 'reactstrap';

export class ReceiptFieldEditorComponent extends Component {

    render() {
        return (
            (this.props.showDisabled || this.props.patternCondition.enabled) ?
                <FormGroup row>
                    <CustomInput className="col-sm-6" type="switch" id={this.props.patternCondition.field} checked={this.props.patternCondition.enabled} onChange={this.props.toggleCondition} label={this.props.patternCondition.field}/>
                    <Input className="col-sm-5" type="text" value={this.props.patternCondition.value} onChange={this.props.handleChange} disabled={!this.props.patternCondition.enabled}/>
                </FormGroup>
            : ""
        );
    }
}