import 'bootstrap/dist/css/bootstrap.css';
import 'react-table/react-table.css'
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { createBrowserHistory } from "history";
import { ai } from "./TelemetryService";
import reportWebVitals from './reportWebVitals';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const history = createBrowserHistory({ basename: '' });
ai.initialize({ history: history });

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement);

reportWebVitals();