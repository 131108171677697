import React, { Component } from 'react';
import ReactTable from "react-table";
import { Button, UncontrolledPopover, PopoverBody } from 'reactstrap';
import { GlobalAuthenticationContext } from '../../Authentication/AuthenticationContext';

export const ColumnType = {
    Fixed: 1,
    Editable: 2,
    Dropdown: 3,
    CustomContent: 4
}

export class EditableTable extends Component {

    render() {
        const columnDefinitions = this.props.columns;

        let colsToRender = columnDefinitions.map(col => {
            let cell = undefined;
            switch (col.type) {
                case ColumnType.Fixed:
                    cell = undefined;
                    break;
                case ColumnType.Editable:
                    if (GlobalAuthenticationContext.canUserWrite()) {
                        cell = (cellInfo) => this.renderEditableCell(cellInfo);
                    }
                    break;
                case ColumnType.Dropdown:
                    cell = (cellInfo) => this.renderDropdown(cellInfo, col.dropdownElements);
                    break;
                case ColumnType.CustomContent:
                    cell = (cellInfo) => this.renderCustomContent(cellInfo, col.renderContent);
                    break;
                default:
                    throw new Error(`The given column type ${col.type} is not supported.`);
            }

            return {
                Header: col.title,
                accessor: col.accessor ? col.accessor : col.title,
                width: col.width ? col.width : undefined,
                Cell: cell
            };
        });

        if (this.props.onEditButtonClick) {
            colsToRender.push({
                width: 40,
                Cell: (cellInfo) => this.renderEditButton(cellInfo)
            });
        }

        colsToRender = colsToRender.filter(Boolean);

        if (this.props.data) {
            return (
                <ReactTable
                    data={this.props.data}
                    columns={colsToRender}
                    defaultPageSize={50}
                    className="-striped -highlight"
                />
            );
        } else {
            return <div className="alert alert-danger" role="alert">Currently there is no data available.</div>
        }
    }

    updateEntryIfChanged(cellInfo, newContent) {
        const data = [...this.props.data];

        if (data[cellInfo.index][cellInfo.column.id] !== newContent) {
            data[cellInfo.index][cellInfo.column.id] = newContent;
            if (this.props.onUpdateItem) {
                this.props.onUpdateItem(data[cellInfo.index]);
            }
            else {
                console.warn("Property 'onUpdateItem' is not set, no action is performed.")
            }
        }
    }

    renderEditableCell(cellInfo) {
        return (
            <div
                contentEditable
                suppressContentEditableWarning
                onBlur={e => this.updateEntryIfChanged(cellInfo, e.target.innerHTML)}>
                {this.props.data[cellInfo.index][cellInfo.column.id]}
            </div>
        );
    }

    renderDropdown(cellInfo, dropdownElements) {
        const data = [...this.props.data];
        const popoverContent = dropdownElements.map(dropdownElement =>
            <div key={dropdownElement.title}>
                <button onClick={e => this.updateEntryIfChanged(cellInfo, e.target.innerHTML)}
                    className="state-selection-button"
                    style={{ background: dropdownElement.color }}>
                    {dropdownElement.title}
                </button>
                <br />
            </div>
        );

        let id = "dropdown-popover-" + cellInfo.index + "-" + cellInfo.column.id;
        let currentColor = dropdownElements.find(x => x.title === data[cellInfo.index][cellInfo.column.id]).color;
        return (
            <div className="state-button-container">
                <Button id={id} type="button" className="state-button" style={{ background: currentColor }}>
                    {data[cellInfo.index][cellInfo.column.id]}
                </Button>
                <UncontrolledPopover placement="bottom" target={id} trigger="legacy">
                    <PopoverBody>
                        <div >
                            {popoverContent}
                        </div>
                    </PopoverBody>
                </UncontrolledPopover>
            </div>
        );
    }

    renderEditButton(cellInfo) {
        const data = [...this.props.data];
        const item = data[cellInfo.index];
        return (
            <Button color="link" className="p-0 w-100" onClick={() => this.props.onEditButtonClick(this, item)}>
                <i title="Edit item" className="far fa-edit text-center w-100"></i>
            </Button>);
    }

    renderCustomContent(cellInfo, renderContent) {
        const data = [...this.props.data];
        const item = data[cellInfo.index];
        return renderContent(item);
    }
}