import React, { Component } from 'react';
import { Button, FormGroup, Label, Input, TabContent, TabPane, Col, Nav, NavItem, NavLink } from 'reactstrap';
import { ReceiptFieldEditorComponent } from './ReceiptFieldEditorComponent';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import classnames from 'classnames';
import { GlobalAuthenticatedHttpClient } from '../../Authentication/AuthenticatedHttpClient';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../../../TelemetryService';
import './SingleReceiptExamplePattern.css';

class SingleReceiptExamplePattern extends Component {
    constructor(props) {
        super(props);
        this.singleReceiptExamplePatternId = this.props.match.params.singleReceiptExamplePatternId;
        this.toggle = this.toggle.bind(this);
        this.state = {
            singleReceiptExamplePattern: {},
            singleReceiptExample: {},
            loading: true,
            error: undefined,
            activeTab: "1",
            createNew: !this.singleReceiptExamplePatternId,
            receiptRequestPatternConditions: [],
            receiptResponsePatternConditions: [],
            showDisabled: false
        };

        if (!this.singleReceiptExamplePatternId) {
            this.singleReceiptExampleId = this.props.match.params.singleReceiptExampleId;
            this.loadSingleReceiptExample();
        }
        else {
            this.loadSingleReceiptExamplePattern();
        }

        this.toggleReceiptRequestPatternCondition = this.toggleReceiptRequestPatternCondition.bind(this);
        this.toggleReceiptResponsePatternCondition = this.toggleReceiptResponsePatternCondition.bind(this);
        this.handleReceiptRequestPatternChange = this.handleReceiptRequestPatternChange.bind(this);
        this.handleReceiptResponesPatternChange = this.handleReceiptResponesPatternChange.bind(this);
        this.savePattern = this.savePattern.bind(this);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    handleHttpErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }

    transformReceiptExample(example) {
        example["Request"] = JSON.parse(example.Request);
        example["Response"] = JSON.parse(example.Response);
        return example;
    }

    transformPattern(pattern) {
        pattern["RequestPattern"] = JSON.parse(pattern.RequestPattern);
        pattern["ResponsePattern"] = JSON.parse(pattern.ResponsePattern);
        return pattern;
    }

    async loadSingleReceiptExample() {
        try{
            let response = await GlobalAuthenticatedHttpClient.authorizedGet(`api/SingleReceiptExamples/${this.singleReceiptExampleId}`);
            let data = await response.json();
            let singleReceiptExample;
            try {
                singleReceiptExample = this.transformReceiptExample(data);
            } catch (error) {
                this.setState({
                    data: [],
                    loading: false,
                    error: `An error occurred while parsing request and response: ${error.message}`
                });
                return;
            }
            this.setState({
                singleReceiptExample: singleReceiptExample,
                loading: false
            });
            for (const [key, value] of Object.entries(this.state.singleReceiptExample.Request)) {
                if (Array.isArray(value)) {
                    for (let i = 0; i < value.length; i++) {
                        for (const [innerKey, innerValue] of Object.entries(value[i])) {
                            let calculatedKey = `${key}_${i}_${innerKey}`;
                            this.setState({
                                receiptRequestPatternConditions: [...this.state.receiptRequestPatternConditions, {
                                    field: calculatedKey,
                                    value: innerValue,
                                    enabled: true
                                }]
                            });
                        }
                    }
                }
                else {
                    this.setState({
                        receiptRequestPatternConditions: [...this.state.receiptRequestPatternConditions, {
                            field: key,
                            value: value,
                            enabled: true
                        }]
                    });
                }
            }
            for (const [key, value] of Object.entries(this.state.singleReceiptExample.Response)) {
                if (Array.isArray(value)) {
                    for (let i = 0; i < value.length; i++) {
                        for (const [innerKey, innerValue] of Object.entries(value[i])) {
                            let calculatedKey = `${key}_${i}_${innerKey}`;
                            this.setState({
                                receiptResponsePatternConditions: [...this.state.receiptResponsePatternConditions, {
                                    field: calculatedKey,
                                    value: innerValue,
                                    enabled: true
                                }]
                            });
                        }
                    }
                }
                else {
                    this.setState({
                        receiptResponsePatternConditions: [...this.state.receiptResponsePatternConditions, {
                            field: key,
                            value: value,
                            enabled: true
                        }]
                    });
                }
            }          
        } catch(error) {
            this.setState({ data: [], loading: false, error: error.message });
        }
    }

    async loadSingleReceiptExamplePattern() {
        try{
            let response = await GlobalAuthenticatedHttpClient.authorizedGet(`api/singleReceiptExamplePatterns/${this.singleReceiptExamplePatternId}?$expand=SingleReceiptExample`);
            let responseJson = await response.json();
            let singleReceiptExamplePattern = this.transformPattern(responseJson);
            this.setState({
                singleReceiptExamplePattern: singleReceiptExamplePattern,
                singleReceiptExample: this.transformReceiptExample(singleReceiptExamplePattern.SingleReceiptExample),
                loading: false
            });
        } catch(error) {
            console.error(error);
            this.setState({ data: [], loading: false, error: error.message });
        }
    }
                

    toggleReceiptRequestPatternCondition(i) {
        let conditions = this.state.receiptRequestPatternConditions;
        conditions[i].enabled = !conditions[i].enabled;
        this.setState({ receiptRequestPatternConditions: conditions });
    }

    handleReceiptRequestPatternChange(event, i) {
        let conditions = this.state.receiptRequestPatternConditions;
        conditions[i].value = event.target.value;
        this.setState({ receiptRequestPatternConditions: conditions });
    }

    handleReceiptResponesPatternChange(event, i) {
        let conditions = this.state.receiptResponsePatternConditions;
        conditions[i].value = event.target.value;
        this.setState({ receiptResponsePatternConditions: conditions });
    }

    toggleReceiptResponsePatternCondition(i) {
        let conditions = this.state.receiptResponsePatternConditions;
        conditions[i].enabled = !conditions[i].enabled;
        this.setState({ receiptRequestPatternConditions: conditions });
    }

    savePattern() {
        let entry = {};
        entry["RequestPattern"] = JSON.stringify(this.state.receiptRequestPatternConditions);
        entry["ResponsePattern"] = JSON.stringify(this.state.receiptResponsePatternConditions);
        entry["SingleReceiptExample"] = { Id: this.state.singleReceiptExample["Id"] };

        this.createEntry(entry);
    }

    async createEntry(entry) {
        try{
        await GlobalAuthenticatedHttpClient.authorizedPost(`api/singleReceiptExamplePatterns`, JSON.stringify(entry));
        window.location.pathname = "examples/SingleReceiptExamples"; 
        } catch(error) {
            console.error("Could not insert entry: " + error);
        }
    }

    renderContent() {
        return this.state.error
            ? <div className="alert alert-danger" role="alert">{this.state.error}</div>
            : (
                <div>
                    <div>
                        <div className="header-container">
                            {this.state.createNew
                                ? <h1>Create New Pattern for Single Receipt Example</h1>
                                :
                                <FormGroup row>
                                    <Label for="id" sm={4}>Id</Label>
                                    <Col sm={8}>
                                        <Input type="text" name="id" readOnly value={this.state.singleReceiptExamplePattern.Id} />
                                    </Col>
                                </FormGroup>
                            }
                        </div>

                        <FormGroup row>
                            <Label for="single-receipt-example-id" sm={4}>Single Receipt Example Id</Label>
                            <Col sm={8}>
                                <Input type="text" name="single-receipt-example-id" readOnly value={this.state.singleReceiptExample.Id} />
                            </Col>
                        </FormGroup>
                    </div>
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => this.toggle('1')}>
                                Receipt request
                </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => this.toggle('2')}>
                                Receipt response
                </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab} style={{ padding: '1rem' }}>
                        <TabPane tabId="1">
                            <div className="row">
                                <div className="col-sm-6">
                                    <JSONInput placeholder={this.state.singleReceiptExample.Request} theme='default' height='600px' viewOnly confirmGood={false} locale={locale} />
                                </div>
                                <div className="patternConditionCard col-sm-6">
                                    {
                                        this.state.receiptRequestPatternConditions.map((patternCondition, i) => {
                                            return (
                                                <ReceiptFieldEditorComponent key={i} showDisabled={this.state.showDisabled} patternCondition={patternCondition} handleChange={(event) => this.handleReceiptRequestPatternChange(event, i)} toggleCondition={() => this.toggleReceiptRequestPatternCondition(i)} />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                            <div className="row">
                                <div className="col-sm-6">
                                    <JSONInput className="col-sm-6" placeholder={this.state.singleReceiptExample.Response} theme='default' height='600px' viewOnly confirmGood={false} locale={locale} />
                                </div>
                                <div className="patternConditionCard col-sm-6">
                                    {
                                        this.state.receiptResponsePatternConditions.map((patternCondition, i) => {
                                            return (
                                                <ReceiptFieldEditorComponent key={i} showDisabled={this.state.showDisabled} patternCondition={patternCondition} handleChange={(event) => this.handleReceiptResponesPatternChange(event, i)} toggleCondition={() => this.toggleReceiptResponsePatternCondition(i)} />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </TabPane>
                    </TabContent>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" onClick={() => this.setState({ showDisabled: !this.state.showDisabled })} id="inlineCheckbox1" />
                        <label className="form-check-label" htmlFor="inlineCheckbox1">Click here to show/hide disabled elements ({this.state.receiptResponsePatternConditions.filter(x => !x.enabled).length + this.state.receiptRequestPatternConditions.filter(x => !x.enabled).length}) </label>
                    </div>
                    <div className="header-container">
                        {this.state.createNew
                            ? <Button className="btn-add" onClick={() => this.savePattern()} color="success">Create</Button>
                            : <Button className="btn-add" color="success">Save</Button>
                        }
                    </div>
                </div>);
    }

    render() {
        return (
            <div>
                <div className="header-container">
                    <h1 className="header">{this.state.apiEndpoint}</h1>
                </div>
                {this.state.loading
                    ? <p><em>Loading...</em></p>
                    : this.renderContent()
                }
            </div>
        );
    }
}

export default withAITracking(ai.reactPlugin, SingleReceiptExamplePattern);