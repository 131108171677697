import React, { Component } from 'react';
import { WriteAccessRequiredButton } from '../../UI/WriteAccessRequiredButton/WriteAccessRequiredButton'
import { GlobalAuthenticatedHttpClient } from '../../Authentication/AuthenticatedHttpClient';
import { EditableTable, ColumnType } from '../../UI/EditableTable/EditableTable';
import { AddOrEditModal } from '../../UI/AddOrEditModal/AddOrEditModal';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../../../TelemetryService';
import '../BrowseData.css';

const endPoint = "api/Industries";

class BrowseIndustries extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
        this.modalRef = React.createRef();
    }

    async componentDidMount() {
        await this.loadData();
    }

    render() {
        let columns = [
            { type: ColumnType.Fixed, title: "Id" },
            { type: ColumnType.Editable, title: "Title" },
            { type: ColumnType.Editable, title: "Description" },
        ];

        let content = (
            <div>
                {this.getAddElementModal()}
                <div className="header-container">
                    <h1 className="header">Browse Industries</h1>
                    <WriteAccessRequiredButton className="btn-add" color="success" onClick={() => this.modalRef.current.toggleModal()}>Add new item</WriteAccessRequiredButton>
                </div>
                <EditableTable data={this.state.data} columns={columns} onUpdateItem={item => this.updateItem(item)}></EditableTable>
            </div>);

        return this.state.loading
            ? <p><em>Loading...</em></p>
            : content;
    }

    async updateItem(item) {
        await GlobalAuthenticatedHttpClient.authorizedPut(`${endPoint}/${item.Id}`, JSON.stringify(item));
    }

    async createItem(item) {
        await GlobalAuthenticatedHttpClient.authorizedPost(endPoint, JSON.stringify(item));
        await this.loadData();
    }

    async loadData() {
        let response = await GlobalAuthenticatedHttpClient.authorizedGet(endPoint);
        let data = await response.json();
        if (data) {
            this.setState({
                data: data.value, 
                loading: false
            });
        }
    }

    getAddElementModal() {
        let inputs = [
            {accessor: "Title", label: "Title"},
            {accessor: "Description", label: "Description"},
        ];
        return <AddOrEditModal ref={this.modalRef} inputs={inputs} title="New Industry" onSubmit={item => this.createItem(item)} />;
    }
}

export default withAITracking(ai.reactPlugin, BrowseIndustries);