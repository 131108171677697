import React, { Component } from 'react';

export class Home extends Component {
    static displayName = Home.name;
    
    render() {
        return (
            <div>
                <h1>Business case database</h1>
                Display and edit data from the fiskaltrust business case database.
            </div>
        );
    }
}
